import { AppConstants } from "../constants";

export const wtSaveToLocalStorage = (key, data) => {
  localStorage.setItem(
    AppConstants.app_code + '_' + key,
    JSON.stringify({ type: typeof data, value: data })
  );
};

export const wtGetFromLocalStorage = (key, defaultData) => {
  try {
    // Loads as json
    const data = localStorage.getItem(AppConstants.app_code + '_' + key);

    // If not found then send default data
    if (!data) {
      return defaultData;
    }

    // Else parse json
    const load = JSON.parse(data);

    // Return the saved value
    return load.value;
  } catch (e) {
    console.log('LocalStorage: ' + key, e.toString());
    return '';
  }
};
